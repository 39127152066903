<template>
  <div class="ReviewDetail modal">
    <div class="modal_cover" @click="close"></div>
    <div class="modal_content">
      <div class="m_header">
        <div>{{ name }} 제품 리뷰 상세 데이터</div>
        <button @click="close" class="close">
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="m_body">
        <div class="buttonWrap">
          <button :class="{ active: tabOne }" @click="handleTab(1)">
            긍정
          </button>
          <button :class="{ active: tabTwo }" @click="handleTab(2)">
            부정
          </button>
          <button :class="{ active: tabThree }" @click="handleTab(0)">
            보통
          </button>
        </div>
        <div class="reviewList">
          <div>
            <table class="tr7" v-if="reviewList.filter((data) => data.ABSA_predict == ABSA_predict).length > 0">
              <tr>
                <th>번호</th>
                <th>날짜</th>
                <th>리뷰 소스</th>
                <th>리뷰 내용</th>
                <th>별점</th>
                <th>신뢰도</th>
                <!-- <th>감성분석</th> -->
                <th>NPS 점수</th>
              </tr>
              <tr v-for="(data, i) in reviewList.filter((data) => data.ABSA_predict == ABSA_predict)" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ moment(data.review_date).format("YYYY.MM.DD") }}
                </td>
                <td>{{ data.review_source }}</td>
                <td>{{ data.sentence }}</td>
                <td>{{ data.rating }}</td>
                <td>{{ data.credibility == "true" ? "신뢰" : "미신뢰" }}</td>
                <td>{{ data.NPS_Score }}</td>
              </tr>
            </table>

            <div class="nodata" v-else>데이터가 없습니다.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  props: {
    reviewList: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      moment: moment,
      visible: false,
      asin: "",
      tabOne: true,
      tabThree: false,
      tabTwo: false,
      ABSA_predict: 1,
      productName: "",
    };
  },

  computed: {
    ...mapState(["userInfo", "type"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleTab(tab) {
      this.tabTwo = false;
      this.tabThree = false;
      this.tabOne = false;
      this.ABSA_predict = tab;
      if (tab == 1) {
        this.tabOne = true;
      } else if (tab == 2) {
        this.tabTwo = true;
      } else if (tab == 0) {
        this.tabThree = true;
      }
    },
  },
};
</script>
